<template>
  <div>
    <v-overlay :value="overlay" absolute>
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-card flat tile>
      <v-toolbar flat color="#ececec" height="60" class="fixed-bar">
        <v-toolbar-title>
          <v-icon class="mb-1">mdi-truck</v-icon>
          Recibir cajas
        </v-toolbar-title>
        <v-divider class="mx-4" inset vertical></v-divider>
        <v-spacer></v-spacer>
      </v-toolbar>

      <v-card-text v-if="paso1">
        <v-toolbar class="my-2">
          <v-btn small color="blue" dark @click="nuevaRecepcion()"
            ><v-icon>mdi-plus</v-icon>Nueva recepción</v-btn
          >
        </v-toolbar>

        <v-row class="pa-4">
          <v-col cols="12" class="px-4 py-0">
            <v-autocomplete
              class="py-0"
              v-model="datosRec.COND_ID"
              label="Conductor"
              :items="conductores"
              item-text="ENTE_NOMBRE"
              item-value="ENTE_ID"
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" class="px-4 py-0">
            <v-text-field
              class="py-0"
              label="Fecha"
              v-model="datosRec.RCARGA_FECHA"
            ></v-text-field>
          </v-col>

          <v-col cols="12" class="px-4 py-0">
            <v-text-field
              class="py-0"
              label="Placa"
              v-model="datosRec.RCARGA_PLACA"
            ></v-text-field>
          </v-col>

          <v-col cols="12" class="px-4 py-0">
            <v-autocomplete
              class="py-0"
              v-model="datosRec.USUARIO_ID"
              label="Usuarios"
              :items="usuarios"
              item-text="ENTE_NOMBRE"
              item-value="ENTE_ID"
            ></v-autocomplete>
          </v-col>
        </v-row>
        <v-row class="px-4 pt-0 pb-4">
          <v-col cols="12"
            ><v-btn @click="guardarRcarga()" small color="primary" dark
              ><v-icon>mdi-arrow-right-bold</v-icon>Siguiente</v-btn
            ></v-col
          >
        </v-row>
      </v-card-text>

      <!-- buscar fincas y guias hijas -->
      <v-card-text v-if="paso2">
        <v-toolbar flat>
          <v-toolbar-title>
            <v-icon class="mb-1">mdi-airplane-edit</v-icon>
            Guias carga
          </v-toolbar-title>
          <v-divider class="mx-2" inset vertical></v-divider>
          <v-col cols="4">
            <v-autocomplete
              clearable
              v-model="datosCoord"
              label="Finca"
              :items="fincas"
              item-text="ENTE_NOMBRE"
              item-value="ENTE_ID"
              @change="cargarGuiasHijas()"
            ></v-autocomplete>
          </v-col>
          <v-col cols="3">
            <v-autocomplete
              clearable
              v-model="datosGuiam"
              label="Guias"
              :items="guiasm"
              item-text="AWB"
              item-value="CABECERA_ID"
              @change="cargarGuiasHijas()"
            ></v-autocomplete>
          </v-col>
          <v-col cols="3">
            <v-text-field v-model="datosHawb" label="HAWB"></v-text-field>
          </v-col>
        </v-toolbar>

        <v-row class="px-2">
          <v-col cols="12" class="pa-4">
            <v-data-table
              :item-class="colorFila"
              :items="guiasHijas"
              :headers="headerDetalle"
            >
              <template v-slot:item.opciones="{ item }">
                <v-btn small icon @click="agregarItem(item)"
                  ><v-icon>mdi-plus</v-icon></v-btn
                >
              </template>
              <template v-slot:item.SCLIENTE="{ item }"
                >{{ item.SCLIENTE }}
              </template>
            </v-data-table>
          </v-col>
        </v-row>
        <v-row class="px-4 mb-2 py-0">
          <v-col cols="12"
            ><v-btn @click="mostrarPaso3()" small color="primary" dark
              ><v-icon>mdi-arrow-right-bold</v-icon>Siguiente</v-btn
            ></v-col
          >
        </v-row>
      </v-card-text>

      <!-- ingresar temperaturas -->
      <v-card-text v-if="paso2">
        <v-toolbar flat>
          <v-toolbar-title>
            <v-icon class="mb-1">mdi-thermometer</v-icon>
            Ingresar temperatura
          </v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
        </v-toolbar>

        <v-data-table :items="itemsRcarga" :headers="headerDetRcarga">
          <template v-slot:item.opciones="{ item }">
            <v-btn small icon @click="borrarItem(item)"
              ><v-icon small>mdi-delete</v-icon></v-btn
            >
            <v-btn small icon @click="editarItem(item)"
              ><v-icon>mdi-pencil</v-icon></v-btn
            >
          </template>
        </v-data-table>
        {{ itemsRcarga }}
        <v-row class="pa-2">
          <v-col cols="6"
            ><v-btn small color="blue" dark
              ><v-icon>mdi-plus</v-icon>Agregar otro item</v-btn
            ></v-col
          >
          <v-col cols="6">
            <v-btn @click="pasoSiguiente(3)" small color="primary" dark
              ><v-icon>mdi-arrow-right-bold</v-icon>Finalizar</v-btn
            >
          </v-col>
        </v-row>
      </v-card-text>

      <!-- guardar datos e imprimir -->
      <v-card-text v-if="paso3">
        <v-toolbar>
          <v-toolbar-title>
            <v-icon class="mb-1">mdi-invoice-text-send-outline</v-icon>
            Finalizar
          </v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
        </v-toolbar>
        <v-row class="pa-6">
          <v-col cols="4">
            <v-btn small color="primary" dark @click="imprimirRecepcion()"
              ><v-icon left>mdi-printer</v-icon>Imprimir</v-btn
            >
          </v-col>

          <v-col cols="4">
            <v-btn small color="primary" dark
              ><v-icon>mdi-send</v-icon>Enviar correo</v-btn
            >
          </v-col>

          <v-col cols="4">
            <v-btn small color="primary" @click="pasoSiguiente(2)" dark
              ><v-icon>mdi-arrow-left</v-icon>Anterior</v-btn
            >
          </v-col>
        </v-row>
      </v-card-text>

      <!-- fin card principal -->
    </v-card>
    <!-- DIALOGO INGRESO DE TEMPERATURA -->
    <v-dialog v-model="dialogIngresarTemp" width="50%">
      <v-card>
        <v-toolbar height="40" flat
          ><v-toolbar-title>Ingresar cajas</v-toolbar-title></v-toolbar
        >
        <v-card-text>
          <v-row>
            <v-col>
              <v-text-field
                label="Cajas"
                v-model="datosItemRcarga.CAJAS_REC"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field
                label="Temperatura 1"
                v-model="datosItemRcarga.TEMP1"
              ></v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                label="Temperatura 2"
                v-model="datosItemRcarga.TEMP2"
              ></v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                label="Temperatura 3"
                v-model="datosItemRcarga.TEMP3"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field
                label="Observaciones"
                v-model="datosItemRcarga.OBSERV"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-toolbar>
          <v-col>
            {{ datosItemRcarga.DETALLE_ID }}
            <v-btn small color="primary" @click="guardarTemp()" dark
              ><v-icon>mdi-content-save</v-icon>Guardar</v-btn
            >
          </v-col>
        </v-toolbar>
      </v-card>
    </v-dialog>
  </div>
</template>
<style>
.fixed-bar {
  position: sticky;
  position: -webkit-sticky; /* for Safari */
  top: 1px;
  z-index: 2;
}
.fila_verde {
  background-color: #b2f78a;
}
.fila_amarilla {
  background-color: #f7eda5;
}
.fila_roja {
  background-color: "red";
}
</style>
<script>
import { mapState, mapMutations, mapActions, mapGetters } from "vuex";
import moment from "moment";
import Vue from "vue";

export default {
  name: "RecibirCajas",
  components: {},
  props: {},
  data: () => ({
    overlay: false,

    datosRec: {
      RCARGA_ID: "",
      RCARGA_PLACA: "",
      USUARIO_ID: "",
      CABECERA_ID: "",
      HCOORD_ID: "",
      COND_ID: "",
      RCARGA_FECHA: "",
      RCARGA_HORA: "",
    },
    conductores: [],
    usuarios: [],
    fincas: [],
    guiasm: [],
    datosCoord: [],
    datosGuiam: "",
    datosHawb: "",
    headerDetalle: [
      { text: "Opciones", align: "start", value: "opciones" },
      { text: "GUIA", align: "start", value: "NUM_GUIA_FINCA" },
      { text: "Finca", align: "start", value: "FINCA" },
      { text: "Cliente", align: "start", value: "SCLIENTE" },
      { text: "Coordinado", align: "start", value: "BOXES_COORD" },
      { text: "Confirmado", align: "start", value: "CAJAS_CONF" },
      { text: "Cliente", align: "start", value: "CAJAS_REC" },
    ],
    guiasHijas: [],
    buscarFinca: "",

    headerDetRcarga: [
      { text: "Opciones", align: "start", value: "opciones" },
      { text: "Finca", align: "start", value: "FINCA_NOMBRE" },
      { text: "Cliente", align: "start", value: "SCLIENTE_NOMBRE" },
      { text: "Cajas rec", align: "start", value: "CAJAS_REC" },
      { text: "Temp", align: "start", value: "TEMP" },
      { text: "Peso", align: "start", value: "PESO" },
      { text: "Estado caja", align: "start", value: "ECAJA_NOMBRE" },
      { text: "Observaciones", align: "start", value: "OBSERV" },
    ],
    itemsRcarga: [],
    paso1: true,
    paso2: false,
    paso3: false,
    paso4: false,
    dialogIngresarTemp: false,
    datosItemRcarga: {},
  }),
  computed: {
    ...mapState("master", ["loadingBtn"]),
  },
  methods: {
    ...mapActions("master", ["requestApi", "alertNotification"]),
    ...mapMutations("master", [
      "setUrl",
      "setOverlay",
      "setMenu",
      "setTitleToolbar",
      "setLoadingTable",
      "setDrawer",
    ]),
    ...mapActions("master", ["requestApi", "alertNotification"]),
    pasoSiguiente(n) {
      if (n == 1) {
        this.paso1 = this.paso2 = this.paso3 = this.paso4 = false;
        this.paso2 = true;
      }
      if (n == 2) {
        this.paso1 = this.paso2 = this.paso3 = this.paso4 = false;
        this.paso2 = true;
      }
      if (n == 3) {
        this.paso1 = this.paso2 = this.paso3 = this.paso4 = false;
        this.paso3 = true;
      }
    },
    cargarDatosForm() {
      this.setLoadingTable(true);
      this.setUrl("api/datos-form-rcarga");
      this.requestApi({
        method: "GET",
        data: {},
      })
        .then((res) => {
          this.fincas = res.data.fincas;
          this.conductores = res.data.conductores;
          this.usuarios = res.data.usuarios;
          this.guiasm = res.data.guias;
          this.setLoadingTable(false);
        })
        .catch(() => {})
        .then(() => {
          this.setLoadingTable(false);
        });
    },

    cargarGuiasHijas() {
      this.setLoadingTable(true);
      this.setUrl("api/rc-cargar-guiasHijas");
      this.requestApi({
        method: "GET",
        data: {
          num_guia_finca: "",
          awb: this.datosGuiam,
          finca_id: this.datosCoord,
        },
      })
        .then((res) => {
          this.guiasHijas = res.data;
          this.setLoadingTable(false);
        })
        .catch(() => {})
        .then(() => {
          this.setLoadingTable(false);
        });
    },

    nuevaRecepcion() {
      this.datosRec.RCARGA_FECHA = new Date().toISOString().substring(0, 10);
      this.datosRec.USUARIO_ID = "";
      this.datosRec.COND_ID = "";
      this.datosRec.RCARGA_PLACA = "";
      this.datosRec.RCARGA_ID = "";
    },

    agregarItem(item) {
      //console.log(item);
      //VERIFICAR SI EL ITEM YA ESTÁN EN LA LISTA
      for (let i = 0; i < this.itemsRcarga.length; i++) {
        if (this.itemsRcarga[i].DETALLE_ID == item.DETALLE_ID) {
          alert("Este item ya se encuentra en la lista");
          return false;
        }
      }
      console.log(item);
      this.dialogIngresarTemp = true;
      this.datosItemRcarga.BOXES_COORD = parseFloat(
        this.datosItemRcarga.BOXES_COORD
      );
      this.datosItemRcarga.CAJAS_REC = parseFloat(
        this.datosItemRcarga.CAJAS_REC
      );
      Object.assign(this.datosItemRcarga, item);
      this.datosItemRcarga.TEMP1 = 0;
      this.datosItemRcarga.TEMP2 = 0;
      this.datosItemRcarga.TEMP3 = 0;
      this.datosItemRcarga.OBSERV = "";
    },
    borrarItem(item) {
      for (var i = this.itemsRcarga.length - 1; i >= 0; --i) {
        if (this.itemsRcarga[i] == item) {
          this.itemsRcarga.splice(i, 1);
        }
      }
    },
    editarItem(item) {
      this.dialogIngresarTemp = true;
      Object.assign(this.datosItemRcarga, item);
      //abrir dialog ingreso de
      //temperatura
      //cajas recibidas
      //observaciones
      //
    },
    guardarRecepcion() {
      //crear recepcion de carga y detalle
    },
    imprimirRecepcion() {
      //enviar comprobante a la impresora
      this.setLoadingTable(true);
      this.setUrl("api/guardar-rcarga");
      this.requestApi({
        method: "POST",
        data: { datosItems: this.itemsRcarga, datosRcarga: this.datosRec },
      })
        .then((res) => {
          // this.fincas = res.data.fincas;
          // this.conductores = res.data.conductores;
          // this.usuarios = res.data.usuarios;
          // this.guiasm = res.data.guias;
          this.setLoadingTable(false);
        })
        .catch(() => {})
        .then(() => {
          this.setLoadingTable(false);
        });
    },
    enviarRecepcion() {
      //mostrar correos a los que se va a enviar el comprobante
      //
    },
    //////
    guardarRcarga() {
      this.pasoSiguiente(2);
    },
    mostrarPaso3() {
      this.pasoSiguiente(3);
    },

    guardarTemp() {
      //calcular promedio temperatura
      //validar ingreso de cajas
      ///-no ingresar detalle_id duplicado en la misma recepcion
      ///-no sobrepasar el total de cajas coordinadas
      console.log(this.datosItemRcarga);
      //let TEMP1 =  this.datosItemRcarga.TEMP1,
      //let TEMP2= this.datosItemRcarga.TEMP2;
      //let TEMP3= this.datosItemRcarga.TEMP3;
      let temp = (
        (parseInt(this.datosItemRcarga.TEMP1) +
          parseInt(this.datosItemRcarga.TEMP2) +
          parseInt(this.datosItemRcarga.TEMP3)) /
        3
      ).toFixed(2);
      const i = {
        FINCA_ID: "",
        FINCA_NOMBRE: this.datosItemRcarga.FINCA,
        SCLIENTE_ID: "",
        SCLIENTE_NOMBRE: this.datosItemRcarga.SCLIENTE,
        CAJAS_REC:
          this.datosItemRcarga.BOXES_COORD - this.datosItemRcarga.CAJAS_REC,
        TEMP: temp,
        PESO: 0,
        OBSERV: this.datosItemRcarga.OBSERV,
        DETALLE_ID: this.datosItemRcarga.DETALLE_ID,
        FINCA_ID: this.datosItemRcarga.FINCA_ID,
        CABECERA_ID: this.datosItemRcarga.CABECERA_ID,
        CAJAS_COORD: this.datosItemRcarga.CDSDetalleBOXES_COORD,
        CNEE_ID: this.datosItemRcarga.CNEE_ID,
        SCLIENTE: this.datosItemRcarga.SCLIENTE_ID,
        TIPO_MOV: 1,
        DESC_MOV: "Recepción e carga",
        DETREC_ESTADO: 0,
        CAJAS_DEV: 0,
        TEMP1: this.datosItemRcarga.TEMP1,
        TEMP2: this.datosItemRcarga.TEMP2,
        TEMP3: this.datosItemRcarga.TEMP3,
      };

      //self.CDSDetRcargaDETALLE_ID.Value:= self.CDSDetalleDETALLE_ID.Value;
      //self.CDSDetRcargaFINCA_ID.Value:= self.CDSDetalleFINCA_ID.Value;
      //self.CDSDetRcargaCABECERA_ID.Value:= self.CDSDetalleCABECERA_ID.Value;
      //self.CDSDetRcargaCAJAS_COORD.AsFloat:= self.CDSDetalleBOXES_COORD.AsFloat;
      //self.CDSDetRcargaCNEE_ID.Value:= self.CDSDetalleCNEE_ID.Value;
      //self.CDSDetRcargaSCLIENTE_ID.Value:= self.CDSDetalleSCLIENTE_ID.Value;
      //self.CDSDetRcargaRCARGA_ID.Value:= self.CDSRecCargaRCARGA_ID.Value;
      //self.CDSDetRcargaCAJAS_REC.Value:= self.CDSDetalleBOXES_COORD.AsFloat - self.CDSDetalleCAJAS_REC.AsFloat;
      //self.CDSDetRcargaTIPO_MOV.Value:= 1;
      //self.CDSDetRcargaDESC_MOV.Value:='Recepci\F3n de carga';
      //self.CDSDetRcargaDETREC_ESTADO.Value:=0;
      //self.CDSDetRcargaCAJAS_DEV.Value:=0;
      this.itemsRcarga.push(i);
      this.dialogIngresarTemp = false;
    },
    colorFila(item) {
      if (item.ESTADO_REC == 0) {
        //COLOR_CAJAS_COORD
      }
      if (item.ESTADO_REC == 1) {
        //COLOR_CAJAS_PARCIAL
      }
      if (item.ESTADO_REC == 2) {
        //3 BOXES_COORD
        //4 CAJAS_REC
        //5 CAJAS_CONF
        //6 CAJAS_DEV
        // if self.DBGrid1.Columns[3].Field.AsFloat < self.DBGrid1.Columns[4].Field.AsFloat then
        //                 ColorListo:= ColorCajasMayor;
        //         if self.DBGrid1.Columns[3].Field.AsFloat = self.DBGrid1.Columns[4].Field.AsFloat then
        //                 ColorListo:= ColorCajasRec;
      }
      if (item.ESTADO_REC == 3) {
        // if self.DBGrid1.Columns[4].Field.asFloat > (self.DBGrid1.Columns[5].Field.asFloat + self.DBGrid1.Columns[6].Field.asFloat) then
        //           ColorListo:= ColorCajasEspera;
        //       if self.DBGrid1.Columns[4].Field.asFloat = (self.DBGrid1.Columns[5].Field.asFloat + self.DBGrid1.Columns[6].Field.asFloat) then
        //           ColorListo:= ColorCajasRec;
      }

      return "white";
    },
  },
  watch: {
    datosCoord: function (val) {
      if (val == null) {
        this.cargarGuiasHijas();
      }
    },
  },
  mounted() {
    this.cargarDatosForm();
    this.setDrawer(false);
  },
};
</script>
