<style>
.v-text-field input {
  font-size: 0.75em;
}
.v-textarea textarea {
  font-size: 0.75em;
  line-height: 1.2rem;
}
.pre-formatted {
  white-space: pre;
}
</style>
<template>
  <div>
    <v-card>
      <v-toolbar flat color="white">
        <v-toolbar-title>
          <v-icon class="mb-1">mdi-airplane</v-icon>
          GUIAS AÉREAS
        </v-toolbar-title>
        <v-divider class="mx-2" inset vertical></v-divider>
        <v-spacer></v-spacer>
        <v-text-field
          v-model="awb"
          @click:append="cargarLista()"
          @keyup.enter="cargarLista()"
          clearable
          small
          append-icon="mdi-magnify"
          label="AWB"
          single-line
          class="mt-5 mx-1"
        >
        </v-text-field>
        <v-spacer></v-spacer>
        <v-text-field
          v-model="desde"
          @click:append="cargarLista()"
          @keyup.enter="cargarLista()"
          @change="cargarLista()"
          small
          type="date"
          append-icon="mdi-magnify"
          label="Desde"
          single-line
          class="mt-5 mx-1"
        >
        </v-text-field>
        <v-text-field
          v-model="hasta"
          @click:append="cargarLista()"
          small
          @keyup.enter="cargarLista()"
          type="date"
          clearable
          @change="cargarLista()"
          append-icon="mdi-magnify"
          label="Hasta"
          single-line
          class="mt-5 mx-1"
        >
        </v-text-field>
        <v-spacer></v-spacer>
        <v-autocomplete
          v-model="aerolineaId"
          small
          item-text="AEROLINEA_NOMBRE"
          item-value="AEROLINEA_ID"
          :items="listaAerolineas"
          @change="cargarLista()"
          clearable
          label="Aerolínea"
          class="mt-5"
        >
        </v-autocomplete>
        <v-spacer></v-spacer>
        <v-autocomplete
          v-model="estadoId"
          small
          item-text="NOM_ESTADO"
          item-value="COD_ESTADO"
          :items="[
            { COD_ESTADO: 'ABIERTA', NOM_ESTADO: 'ABIERTA' },
            { COD_ESTADO: 'RESERVADA', NOM_ESTADO: 'RESERVADA' },
            { COD_ESTADO: 'CERRADA', NOM_ESTADO: 'CERRADA' },
          ]"
          @change="cargarLista()"
          clearable
          label="Estado"
          class="mt-5"
        >
        </v-autocomplete>
      </v-toolbar>

      <v-data-table
        ref="dtGuias"
        :headers="headers"
        :items="lista"
        :items-per-page="itemsPerPage"
        :search="busqueda"
        :options.sync="options"
        hide-default-footer
        :loading="loadingTable"
        class="elevation-1"
        @keydown:row="handleKeyDown"
      >
        <template v-slot:item.opcion="{ item }">
          <!-- <v-btn
            v-if="item.ESTADO == 'CERRADA'"
            icon
            color="red"
            target="_blank"
            @click="editarGuia(item.AWB)"
          >
            <v-icon color="primary">mdi-send</v-icon>
          </v-btn> -->

          <v-menu bottom left>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon color="green" v-on="on" v-bind="attrs">
                <v-icon color="primary">mdi-dots-horizontal </v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                @click="editarGuia(item.AWB)"
                v-if="item.ESTADO == 'CERRADA'"
              >
                <v-list-item-title>
                  <v-icon left color="primary">mdi-send</v-icon>
                  Transmitir Mensaje
                </v-list-item-title>
              </v-list-item>

              <v-list-item
                @click="abrirMensajeCorreo(item)"
                v-if="item.ESTADO == 'CERRADA'"
              >
                <v-list-item-title>
                  <v-icon left color="primary">mdi-email-outline</v-icon>
                  Mensaje Correo</v-list-item-title
                >
              </v-list-item>

              <v-list-item
                @click="abrirOPD(item)"
                v-if="item.ESTADO == 'CERRADA'"
              >
                <v-list-item-title
                  ><v-icon left color="primary">mdi-file-delimited</v-icon
                  >Archivo OPD</v-list-item-title
                >
              </v-list-item>

              <v-list-item
                @click="exportarSBDW(item)"
                v-if="item.ESTADO == 'CERRADA'"
              >
                <v-list-item-title
                  ><v-icon left color="primary">mdi-file-excel</v-icon>Exportar
                  SBDW (excel)</v-list-item-title
                >
              </v-list-item>

              <v-list-item
                @click="iniciarPreAlerta(item)"
                v-if="item.ESTADO == 'CERRADA' || item.ESTADO == 'ABIERTA'"
              >
                <v-list-item-title
                  ><v-icon left color="primary">mdi-file-send-outline</v-icon>
                  Enviar pre alerta</v-list-item-title
                >
              </v-list-item>
            </v-list>
          </v-menu>
          <v-btn
            v-if="item.LOG_MENSAJES.length > 0"
            icon
            small
            color="green"
            target="_blank"
            @click="cargarLogExportar(item)"
          >
            <v-icon small color="info">mdi-information-outline</v-icon>
          </v-btn>
          <v-btn
            v-if="item.MENSAJES > 0"
            icon
            small
            color="green"
            target="_blank"
            @click="mensajesGuia(item.AWB)"
          >
            <v-icon small color="primary">mdi-email</v-icon>
          </v-btn>
        </template>

        <template v-slot:footer>
          <v-pagination
            class="mt-10"
            v-model="currentPage"
            :length="pageCount"
            total-visible="10"
            @input="handlePageChange"
          ></v-pagination>
        </template>
      </v-data-table>
    </v-card>

    <!-- DIALOG TRANSMISION GUIA -->
    <v-dialog v-model="dialogGuia" persistent max-width="550px">
      <v-card>
        <v-card-title>
          <v-icon class="mx-1">mdi-send</v-icon>
          <span class="headline">Transmitir guía aérea</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form ref="formTransmisionGuia">
              <v-row>
                <v-col class="pt-0 pb-0" cols="6">
                  <v-text-field
                    class="md-12"
                    small
                    v-model="datosGuia.FECHA"
                    label="Fecha"
                  ></v-text-field>
                </v-col>
                <v-col class="pt-0 pb-0" cols="6">
                  <v-text-field
                    readonly
                    class="md-12"
                    maxlength="8"
                    small
                    v-model="datosGuia.AWB"
                    label="Numero de guia"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="pt-0 pb-0" cols="6">
                  <v-text-field
                    class="md-12"
                    maxlength="8"
                    small
                    v-model="datosGuia.NOMBRE_AEROLINEA"
                    label="Aerolinea"
                  ></v-text-field>
                </v-col>
                <v-col class="pt-0 pb-0" cols="6">
                  <v-select
                    small
                    v-model="datosGuia.IDENT_BIENES"
                    :items="[
                      { codigo: 'CONS', desc: 'Consolidado' },
                      { codigo: 'FF', desc: 'Fresh flowers' },
                    ]"
                    item-text="desc"
                    item-value="codigo"
                    label="Tipo de embarque"
                    :rules="requiredRule"
                  >
                  </v-select>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6">
                  <v-text-field
                    class="md-12"
                    maxlength="8"
                    small
                    readonly
                    v-model="datosGuia.ESTADO_TRANSMISION"
                    label="Estado de la transmision"
                  ></v-text-field>
                </v-col>

                <v-col cols="6">
                  <v-text-field
                    class="md-12"
                    maxlength="8"
                    small
                    readonly
                    :rules="requiredRule"
                    v-model="datosGuia.FORMATO_NOMBRE"
                    label="Formato del mensaje"
                  ></v-text-field>
                  <v-btn
                    :loading="btnTransLoading"
                    color="primary"
                    small
                    v-if="datosGuia.ESTADO_TRANSMISION != 'ENVIADA'"
                    @click="transmitirGuia"
                  >
                    <v-icon>mdi-send</v-icon>
                    Transmitir
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions class="mb-4">
          <v-col class="text-right">
            <!-- <v-btn small color="primary" @click="!dialogGuia">
              <v-icon>mdi-content-save</v-icon> Guardar
            </v-btn>
            &nbsp; -->
            <v-btn
              small
              color="info"
              @click="
                dialogGuia = !dialogGuia;
                cargarLista();
              "
            >
              <v-icon>mdi-exit-to-app</v-icon> Salir
            </v-btn>
          </v-col>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- DIALOG MENSAJES -->
    <v-dialog v-model="dialogMensajes" scrollable persistent max-width="950px">
      <v-card>
        <v-toolbar height="50" fixed flat color="blue" dark>
          <v-toolbar-title solid>
            <span class="headline">
              <v-icon class="mx-1">mdi-email</v-icon>
              Mensajes guía aérea {{ guiaMensajesTitle }}</span
            ></v-toolbar-title
          >

          <v-spacer></v-spacer>
          <v-btn small text @click="dialogMensajes = !dialogMensajes"
            ><v-icon>mdi-close</v-icon></v-btn
          >
        </v-toolbar>

        <v-card-text>
          <v-container>
            <v-data-table
              :headers="headersMensajes"
              :items="listaMensajes"
              :items-per-page="itemsPerPage"
              :search="busqueda"
              :options.sync="options"
              hide-default-footer
              :loading="loadingTable"
              class="elevation-1"
            >
              <template v-slot:item.MENSAJE="{ item }">
                <div class="pre-formatted">{{ item.MENSAJE }}</div>
              </template>
              <template v-slot:item.TIPO="{ item }">
                <v-icon v-if="item.TIPO == 'FNA'" color="red"
                  >mdi-alert-circle-outline</v-icon
                >
                <v-icon
                  title="mensaje recibido"
                  v-if="item.TIPO == 'FMA' || item.TIPO == 'FSU'"
                  color="green"
                  >mdi-information-outline</v-icon
                >
                {{ item.TIPO }}
              </template>
            </v-data-table>
          </v-container>
        </v-card-text>
        <!-- <v-card-actions>
          <v-col class="text-center">
            <v-btn small @click="dialogMensajes = !dialogMensajes">
              <v-icon>mdi-exit-to-app</v-icon> Salir
            </v-btn>
          </v-col>
        </v-card-actions> -->
      </v-card>
    </v-dialog>

    <!-- DIALOG MENSAJE CORREO -->
    <v-dialog
      v-model="dialogMensajeCorreo"
      persistent
      max-width="650px"
      scrollable
    >
      <v-card>
        <v-toolbar color="blue" flat dark height="40" fixed>
          <v-icon class="mx-1">mdi-email</v-icon>
          <span class="headline">Enviar mensaje por email </span>
        </v-toolbar>
        <v-card-text>
          <v-container>
            <v-form ref="formMensajeCorreo">
              <v-row class="mt-0 mb-0">
                <v-col class="pt-0 pb-0 mt-0" cols="3">
                  <v-select
                    class="md-12"
                    small
                    v-model="datosMensaje.TIPO_MENSAJE"
                    label="Tipo de mensaje"
                    :items="[
                      { codigo: 'CONS', desc: 'Consolidado' },
                      { codigo: 'FF', desc: 'Fresh flowers' },
                    ]"
                    @change="cargarMensajeAerolinea"
                    item-text="desc"
                    item-value="codigo"
                    :rules="requiredRule"
                  ></v-select>
                </v-col>

                <v-col class="pt-0 pb-0 mt-0" cols="4">
                  <v-select
                    class="md-12"
                    small
                    v-model="datosMensaje.PROCESO"
                    label="Formato"
                    :items="procesos"
                    @change="cargarMensajeAerolinea"
                    item-text="NOMBRE"
                    item-value="ID"
                    :rules="requiredRule"
                  ></v-select>
                </v-col>

                <v-col class="pt-0 pb-0 mt-0" cols="5">
                  <v-checkbox
                    v-model="separarMensajes"
                    label="Un mensaje por guia"
                    title="Envia cada guia en un mensaje de correo separado"
                  ></v-checkbox>
                </v-col>
              </v-row>

              <v-row class="mt-0 mb-1">
                <v-col class="pt-0 pb-1" cols="12">
                  <v-textarea
                    class="md-12"
                    small
                    v-model="datosMensaje.DESTINATARIOS"
                    label="Destinatarios"
                    :rules="requiredRule"
                    rows="2"
                  ></v-textarea>
                </v-col>
              </v-row>

              <v-row>
                <v-col class="pt-0 pb-0" cols="12">
                  <v-textarea
                    class="md-12"
                    small
                    v-model="datosMensaje.MENSAJE"
                    label="Mensaje"
                    :rules="requiredRule"
                  ></v-textarea>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions class="mb-2" fixed>
          <v-col class="text-right">
            <v-btn small color="primary" @click="enviarMensajeCorreo">
              <v-icon>mdi-send</v-icon> Enviar
            </v-btn>
            &nbsp;
            <v-btn
              small
              color="info"
              @click="dialogMensajeCorreo = !dialogMensajeCorreo"
            >
              <v-icon>mdi-exit-to-app</v-icon> Salir
            </v-btn>
          </v-col>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- DIALOG LOG DE ENVIOS -->
    <v-dialog v-model="dialogLog" persistent max-width="450px">
      <v-card>
        <v-card-title>
          <span class="headline">
            <v-icon class="mx-1">mdi-send-clock</v-icon> Historial de mensajes
            guía aérea {{ guiaMensajesTitle }}</span
          >
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-data-table
              :headers="headersLogMensajes"
              :items="logMensajes"
              :items-per-page="itemsPerPage"
              :options.sync="options"
              hide-default-footer
              :loading="loadingTable"
              class="elevation-1"
            >
            </v-data-table>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-col class="text-center">
            <v-btn small @click="dialogLog = !dialogLog">
              <v-icon>mdi-exit-to-app</v-icon> Salir
            </v-btn>
          </v-col>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- DIALOG PRE ALERTA -->
    <v-dialog
      v-model="dialogPreAlerta"
      persistent
      mi-width="800px"
      max-width="850px"
      fullscreen
    >
      <v-card>
        <v-toolbar height="40" flat color="blue" dark>
          <span class="headline">
            <v-icon class="mx-1">mdi-file-send-outline</v-icon> Pre alertas
            guia# {{ prealertaAwb }}</span
          >

          <v-spacer></v-spacer>
          <v-btn @click="dialogPreAlerta = !dialogPreAlerta" small dark icon
            ><v-icon>mdi-close</v-icon></v-btn
          >
        </v-toolbar>
        <v-card-text>
          <v-form ref="formAgendarPre">
            <v-row>
              <v-col class="mt-2" cols="3">
                <v-text-field
                  label="Fecha hora de envío"
                  v-model="fechahora"
                  type="datetime-local"
                  small
                  :rules="requiredRule"
                ></v-text-field>
              </v-col>
              <v-col class="mt-2" cols="4">
                <v-text-field
                  v-model="asuntoPre"
                  small
                  label="Asunto"
                  :rules="requiredRule"
                ></v-text-field>
              </v-col>
              <v-col class="mt-6" cols="4">
                <v-btn
                  title="Agendar envío de prealertas"
                  color="green"
                  small
                  @click="agendarPrealerta"
                  dark
                  ><v-icon small left>mdi-calendar</v-icon>Agendar</v-btn
                >
                <v-btn
                  class="mx-4"
                  title="Cancelar envío de prealertas"
                  color="orange"
                  small
                  @click="cancelarAgendaPrealerta()"
                  dark
                  ><v-icon>mdi-stop</v-icon>Cancelar</v-btn
                >
                <v-btn
                  class="mx-4"
                  title="Refrescar datos"
                  color="blue"
                  small
                  @click="refrescarPrealerta()"
                  dark
                  ><v-icon>mdi-reload</v-icon></v-btn
                >
              </v-col>
            </v-row>
            <v-data-table
              :items="preAlertas"
              :headers="headersPrealertas"
              :search="busqueda"
              :options.sync="options"
              :loading="loadingTable"
              show-select
              v-model="hawbSelected"
              item-key="ID"
              class="elevation-1"
            >
              <template v-slot:item.ESTADO="{ item }">
                <v-btn
                  title="Enviar prealerta"
                  class="ma-1"
                  x-small
                  dark
                  @click="dialogoPrealertaCorreo(item)"
                  color="blue"
                  ><v-icon small left>mdi-send</v-icon>Enviar</v-btn
                >
                <v-chip small dark :color="colorEstado(item)">{{
                  item.ESTADO
                }}</v-chip>

                <v-btn
                  title="Historial de envíos de prealertas"
                  small
                  icon
                  @click="cargarLogPrealerta(item)"
                >
                  <v-icon>mdi-clipboard-text-clock-outline</v-icon>
                </v-btn>
              </template>
              <template v-slot:item.FECHA_ENVIO="{ item }">
                {{
                  item.FECHA_ENVIO != null
                    ? item.FECHA_ENVIO.substring(0, 16)
                    : ""
                }}
              </template>
            </v-data-table>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-col class="text-center">
            <v-btn small @click="dialogPreAlerta = !dialogPreAlerta">
              <v-icon>mdi-exit-to-app</v-icon> Salir
            </v-btn>
          </v-col>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- DIALOG PREALERTA CORREO -->
    <v-dialog
      v-model="dialogPrealertaCorreo"
      persistent
      max-width="1050px"
      scrollable
    >
      <v-card>
        <v-toolbar color="blue" flat dark height="40" fixed>
          <v-icon class="mx-1">mdi-email</v-icon>
          <span class="headline">Enviar prealerta por email </span>

          <v-spacer></v-spacer>
          <v-btn icon @click="dialogPrealertaCorreo = !dialogPrealertaCorreo"
            ><v-icon>mdi-close</v-icon></v-btn
          >
        </v-toolbar>
        <v-card-text>
          <v-container>
            <v-form ref="formPrealertaCorreo">
              <v-row class="mt-0 mb-1">
                <v-col class="pt-0 pb-1" cols="12">
                  <v-textarea
                    class="md-12"
                    small
                    v-model="prealertaMensaje.DESTINATARIOS"
                    label="Destinatarios"
                    :rules="requiredRule"
                    rows="2"
                  ></v-textarea>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-text-field
                    class="md-12"
                    small
                    v-model="prealertaMensaje.ASUNTO"
                    label="Asunto"
                    :rules="requiredRule"
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col class="pt-0 pb-0" cols="12">
                  <v-textarea
                    class="md-12"
                    small
                    v-html="prealertaMensaje.MENSAJE"
                    label="Mensaje"
                    :rules="requiredRule"
                  ></v-textarea>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions class="mb-2" fixed>
          <v-col class="text-right">
            <v-btn small color="primary" @click="enviarPrealerta">
              <v-icon>mdi-send</v-icon> Enviar
            </v-btn>
            &nbsp;
            <v-btn
              small
              color="info"
              @click="dialogPrealertaCorreo = !dialogPrealertaCorreo"
            >
              <v-icon>mdi-exit-to-app</v-icon> Salir
            </v-btn>
          </v-col>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- DIALOG PREALERTA LOG -->
    <v-dialog v-model="dialogPrealertaLog" max-width="1050px" scrollable>
      <v-card>
        <v-toolbar color="blue" flat dark height="40" fixed>
          <v-icon class="mx-1">mdi-clipboard-text-clock-outline</v-icon>
          <span class="headline">Historial de envios por email </span>

          <v-spacer></v-spacer>
          <v-btn icon @click="dialogPrealertaLog = !dialogPrealertaLog"
            ><v-icon>mdi-close</v-icon></v-btn
          >
        </v-toolbar>
        <v-card-text>
          <v-row>
            <v-col cols="6">
              <v-autocomplete
                label="Escoja la fecha"
                v-model="logSel"
                return-object
                :items="prealertaLog"
                item-text="FECHA"
                item-key="ID"
              >
                <template v-slot:item="data">
                  <v-list-item-content>
                    <v-list-item-title
                      v-html="data.item.FECHA.substring(0, 16)"
                    ></v-list-item-title>
                    <v-list-item-subtitle
                      >Enviado por: {{ data.item.USER_NAME }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </template>
              </v-autocomplete>
            </v-col>
            <v-col>
              <v-text-field
                label="Enviado por"
                readonly
                v-model="logSel.USER_NAME"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row class="mt-0 mb-1">
            <v-col class="pt-0 pb-1" cols="12">
              <v-textarea
                class="md-12 pb-0 mb-0"
                small
                v-model="logSel.DESTINATARIO"
                label="Destinatarios"
                readonly
                rows="2"
              ></v-textarea>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field
                class="md-12 pt-0 mt-0"
                small
                v-model="logSel.ASUNTO"
                label="Asunto"
                readonly
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col class="pt-0 pb-0" cols="12">
              <v-textarea
                class="md-12"
                small
                v-html="logSel.MENSAJE"
                label="Mensaje"
                readonly
              ></v-textarea>
            </v-col>
          </v-row>
        </v-card-text> </v-card
    ></v-dialog>

    <v-overlay :opacity="0.95" size="64" :value="overlay" z-index="99999">
      <v-progress-circular indeterminate size="64">
        {{ overlayText }}
      </v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions, mapGetters } from "vuex";
import Vue from "vue";

export default {
  name: "GuiasAereasComponent",

  data: () => ({
    headers: [
      { text: "Opciones", value: "opcion", width: 130 },
      { text: "AWB", value: "AWB" },
      { text: "Fecha", value: "FECHA" },
      { text: "Cliente", value: "CLIENTE_NOMBRE" },
      { text: "Cajas full", value: "NUM_CAJAS_FULL" },
      { text: "Aerolinea", value: "AEROLINEA_NOMBRE" },
      { text: "Estado", value: "AWB_ESTADO" },
      { text: "Estado", value: "ESTADO" },
    ],

    headersMensajes: [
      { text: "Fecha", value: "FECHA" },
      { text: "Tipo", value: "TIPO" },
      { text: "Documento", value: "TIPO_DOC" },
      { text: "Mensaje", value: "MENSAJE" },
    ],

    headersLogMensajes: [
      { text: "Fecha", value: "FECHA" },
      { text: "Tipo", value: "TIPO_MENSAJE" },
      { text: "Usuario", value: "USUARIO_ID" },
    ],
    logMensajes: [],
    lista: [],
    listaMensajes: [],
    options: {},
    notifications: false,
    itemsPerPage: 50,
    pageCount: 1,
    busqueda: "",
    currentPage: 1,
    awb: "",
    desde: "",
    hasta: "",
    separarMensajes: false,
    fechahora: "",

    aerolineaId: "",
    estadoId: "",
    listaAerolineas: [],
    dialogGuia: false,
    dialogMensajes: false,
    dialogLog: false,
    datosGuia: {
      FECHA: "",
      AWB: "",
      NOMBRE_AEROLINEA: "",
      AWB_ESTADO: "",
      IDENT_BIENES: "",
      ESTADO_TRANSMISION: "",
      FORMATO_NOMBRE: "",
    },
    datosMensaje: {
      REMITENTE: "",
      CABECERA_ID: "",
      AWB: "",
      DESTINATARIOS: "",
      MENSAJE: "",
      TIPO_MENSAJE: "",
      FWB: "",
      FHL: "",
      AEROLINEA_ID: "",
      PROCESO: "",
    },
    dialogMensajeCorreo: false,
    dialogOPD: false,
    guiaMensajesTitle: "",
    requiredRule: [(v) => !!v || "El campo es requerido"],
    btnTransLoading: false,
    btnEnviarMenLoad: false,
    procesos: [],
    overlay: false,
    overlayText: "cargando...",
    dialogPreAlerta: false,

    headersPrealertas: [
      { text: "Estado", value: "ESTADO" },
      { text: "Asunto", value: "ASUNTO" },
      { text: "Fecha envio", value: "FECHA_ENVIO" },
      { text: "Enviado por", value: "ENVIADO_POR" },
      { text: "Cliente", value: "CLIENTE" },
    ],
    preAlertas: [],

    dialogPrealertaCorreo: false,
    prealertaMensaje: {
      AWB: "",
      REMITENTE: "",
      DESTINATARIOS: "",
      MENSAJE: "",
      ASUNTO: "",
    },
    hawbSelected: [],
    prealertaAwb: "",
    prealertaCabId: "",
    asuntoPre: "",
    dialogPrealertaLog: false,

    prealertaLog: [],
    logSel: "",
    logMensaje: { ID: "", DESTINATARIO: "", MENSAJE: "" },
  }),
  computed: {
    ...mapState("master", ["loadingTable", "user", "months"]),
    ...mapGetters("access", []),
  },
  methods: {
    ...mapMutations("master", [
      "setUrl",
      "setOverlay",
      "setMenu",
      "setLoadingTable",
      "setTitleToolbar",
    ]),
    ...mapActions("master", ["requestApi", "alertNotification"]),
    colorEstado(item) {
      if (item.ESTADO == "PENDIENTE") {
        return "orange";
      }

      if (item.ESTADO == "ENVIADO") {
        return "green";
      }

      if (item.ESTADO == "ERROR") {
        return "red";
      }
    },
    cargarLista() {
      this.setLoadingTable(true);
      this.setUrl("api/lista-guias");
      this.requestApi({
        method: "GET",
        data: {
          pageCount: this.pageCount,
          itemsPerPage: this.itemsPerPage,
          page: this.currentPage,
          awb: this.awb,
          desde: this.desde,
          hasta: this.hasta,
          aerolinea_id: this.aerolineaId,
          estado_id: this.estadoId,
        },
      })
        .then((res) => {
          //console.log(res);
          this.lista = res.data.lista;
          this.pageCount = res.data.pageCount;
          this.totalItems = res.data.totalItems;
          this.listaAerolineas = res.data.aerolineas;
          //this.tipoEmpresa = res.data.tipoEmpresa
          //console.log(this.totalItems);
        })
        .catch(() => {})
        .then(() => {
          this.setLoadingTable(false);
        });
    },

    cargarDatosGuia(awb) {
      this.setLoadingTable(true);
      this.setUrl("api/datosGuia");
      this.requestApi({
        method: "GET",
        data: {
          awb: awb,
        },
      })
        .then((res) => {
          this.datosGuia = res.data.datosGuia[0];
          if (
            this.datosGuia.FORMATO_NOMBRE == "" ||
            this.datosGuia.FORMATO_NOMBRE == null
          ) {
            if (
              this.datosGuia.FORMATO_NOMBRE == null ||
              this.datosGuia.FORMATO_NOMBRE == ""
            ) {
              this.dialogGuia = false;
              Vue.swal({
                html:
                  "No se ha configurado un método de transmisión para " +
                  this.datosGuia.NOMBRE_AEROLINEA,
                icon: "warning",
                showCancelButton: false,
                confirmButtonText: "Aceptar",
                cancelButtonText: "Cerrar",
              });
            }
          }
          //console.log(this.datosGuia);
        })
        .catch(() => {})
        .then(() => {
          this.setLoadingTable(false);
        });
    },

    editarGuia(awb) {
      this.dialogGuia = true;
      this.cargarDatosGuia(awb);
    },

    transmitirGuia() {
      if (this.datosGuia.FORMATO_NOMBRE == "") {
        alert("aaaaa");
      }
      if (!this.$refs.formTransmisionGuia.validate()) {
        return;
      }

      this.btnTransLoading = true;
      //this.setLoadingTable(true);
      this.setUrl("api/transmitirGuia");
      this.requestApi({
        method: "GET",
        data: {
          awb: this.datosGuia.AWB,
          ident_bienes: this.datosGuia.IDENT_BIENES,
        },
      })
        .then((res) => {
          //this.datosGuia = res.data.datosGuia;
          this.alertNotification({ param: { html: res.data.msg } });
          this.cargarDatosGuia(this.datosGuia.AWB);
          this.btnTransLoading = false;

          //console.log(this.res.data);
        })
        .catch(() => {
          this.btnTransLoading = false;
        })
        .then(() => {
          //this.setLoadingTable(false);
          this.btnTransLoading = false;
        });
    },

    mensajesGuia(guianum) {
      this.guiaMensajesTitle = guianum;
      this.setLoadingTable(true);
      this.setUrl("api/mensajesGuiaDb");
      this.requestApi({
        method: "GET",
        data: {
          awb: guianum,
        },
      })
        .then((res) => {
          this.listaMensajes = res.data.mensajes;
          this.dialogMensajes = true;
          //console.log(res.data.mensajes);
        })
        .catch(() => {})
        .then(() => {
          this.setLoadingTable(false);
        });
    },
    cargarMensajeAerolinea() {
      this.setLoadingTable(true);
      this.setUrl("api/aerolinea-mensaje");
      this.requestApi({
        method: "GET",
        data: {
          guia_id: this.datosMensaje.CABECERA_ID,
          awb: this.datosMensaje.AWB,
          aerolinea_id: this.datosMensaje.AEROLINEA_ID,
          proceso_id: this.datosMensaje.PROCESO,
        },
      })
        .then((res) => {
          if (res.data.success == true) {
            //this.dialogMensajeCorreo = true;
          } else {
            this.alertNotification({ param: { html: res.data.msg } });
          }

          this.datosMensaje.DESTINATARIOS = res.data.dest;
          this.datosMensaje.FWB = res.data.fwb;
          this.datosMensaje.FHL = res.data.fhl;
          this.datosMensaje.REMITENTE = res.data.remitente;

          if (this.datosMensaje.TIPO_MENSAJE == "FF") {
            this.datosMensaje.MENSAJE = res.data.fwb;
          } else {
            this.datosMensaje.MENSAJE = res.data.fwb + "\n" + res.data.fhl;
          }
        })
        .catch(() => {})
        .then(() => {
          this.setLoadingTable(false);
        });
    },
    abrirMensajeCorreo(guia) {
      //console.log(guia);

      this.setLoadingTable(true);
      this.setUrl("api/aerolinea-procesos");
      this.requestApi({
        method: "GET",
        data: {
          //guia_id: guia.CABECERA_ID,
          //awb: guia.AWB,
          aerolinea_id: guia.AEROLINEA_ID,
        },
      })
        .then((res) => {
          if (res.data.success == true) {
            this.dialogMensajeCorreo = true;
          } else {
            this.alertNotification({ param: { html: res.data.msg } });
          }
          this.procesos = res.data.procesos;
          this.datosMensaje.AWB = guia.AWB;
          this.datosMensaje.CABECERA_ID = guia.CABECERA_ID;
          this.datosMensaje.AEROLINEA_ID = guia.AEROLINEA_ID;
        })
        .catch(() => {})
        .then(() => {
          this.setLoadingTable(false);
        });
    },

    enviarMensajeCorreo() {
      if (!this.$refs.formMensajeCorreo.validate()) {
        return;
      }
      this.overlay = true;
      this.overlayText = "enviando..";
      this.setUrl("api/enviarMensaje");
      this.requestApi({
        method: "POST",
        data: {
          guia_id: this.datosMensaje.CABECERA_ID,
          awb: this.datosMensaje.AWB,
          aerolinea_id: this.aerolineaId,
          destinatarios: this.datosMensaje.DESTINATARIOS,
          mensaje: this.datosMensaje.MENSAJE,
          separarMensajes: this.separarMensajes,
          remitente: this.datosMensaje.REMITENTE,
        },
      })
        .then((res) => {
          //console.log(res.data);
          this.overlay = false;
          this.alertNotification({ param: { html: res.data.msg } });
          this.dialogMensajeCorreo = false;

          this.datosMensaje = {
            CABECERA_ID: "",
            AWB: "",
            DESTINATARIOS: "",
            MENSAJE: "",
            TIPO_MENSAJE: "",
            FWB: "",
            FHL: "",
            AEROLINEA_ID: "",
          };

          this.cargarLista();
        })
        .catch(() => {
          this.overlay = false;
        })
        .then(() => {
          this.overlay = false;
          this.setLoadingTable(false);
        });
    },

    abrirOPD(item) {
      this.overlay = true;
      this.overlayText = "Generando archivo OPD";
      this.setUrl("api/descargar-opd");
      this.requestApi({
        method: "GET",
        data: {
          guia_id: item.CABECERA_ID,
          awb: item.AWB,
          al: item.AEROLINEA_NOMBRE,
        },
      })
        .then((res) => {
          //console.log(res.data);
          this.overlay = false;

          let a = document.createElement("a");
          a.href =
            "data:" +
            res.data.archivo.ContentType +
            ";base64," +
            res.data.archivo.datos;
          a.download = res.data.archivo.nombreArchivo;
          a.click();
          //console.log(res);
          this.cargarLista();
        })
        .catch(() => {
          this.overlay = false;
        })
        .then(() => {
          this.overlay = false;
          this.setLoadingTable(false);
        });
    },

    setTipoMensaje() {
      if (this.datosMensaje.TIPO_MENSAJE == "FF") {
        this.datosMensaje.MENSAJE = this.datosMensaje.FWB;
      } else {
        this.datosMensaje.MENSAJE =
          this.datosMensaje.FWB + "\n" + this.datosMensaje.FHL;
      }
    },

    cargarLogExportar(item) {
      this.overlay = true;
      this.overlayText = "Cargando log...";
      this.setUrl("api/awb-mensajes-log");
      this.requestApi({
        method: "GET",
        data: {
          guia_id: item.CABECERA_ID,
          awb: item.AWB,
        },
      })
        .then((res) => {
          //console.log(res.data);
          this.logMensajes = res.data.mensajes_log;
          this.overlay = false;
          this.dialogLog = true;
        })
        .catch(() => {
          this.overlay = false;
        })
        .then(() => {
          this.overlay = false;
          this.setLoadingTable(false);
        });
    },

    handlePageChange() {
      this.cargarLista();
    },

    handleKeyDown(event) {
      const dataTable = this.$refs.dtGuias;
      const currentRowIndex = dataTable.selectedRowIndex;
      console.log(event);
      alert("xx");
      switch (event.keyCode) {
        case 38: // Up arrow key
          if (currentRowIndex > 0) {
            dataTable.selectRow(currentRowIndex - 1);
          }
          break;
        case 40: // Down arrow key
          if (currentRowIndex < dataTable.rows.length - 1) {
            dataTable.selectRow(currentRowIndex + 1);
          }
          break;
      }
    },
    exportarSBDW(item) {
      this.overlay = true;
      this.overlayText = "Generando archivo";
      this.setUrl("api/descargar-sbdw");
      this.requestApi({
        method: "GET",
        data: {
          cabecera_id: item.CABECERA_ID,
          //awb: item.AWB,
        },
      })
        .then((res) => {
          //console.log(res.data);
          this.overlay = false;

          let a = document.createElement("a");
          a.href = "data:" + res.data.ContentType + ";base64," + res.data.datos;
          a.download = res.data.archivo;
          a.click();
          console.log(res);
          //this.cargarLista();
        })
        .catch(() => {
          this.overlay = false;
        })
        .then(() => {
          this.overlay = false;
          this.setLoadingTable(false);
        });
    },
    iniciarPreAlerta(item) {
      this.overlay = true;
      this.overlayText = "Cargando prealertas";
      this.fechahora = "";
      this.hawbSelected = [];
      this.asuntoPre = item.AWB + " Shipment Information";
      this.setUrl("api/crear-prealertas-guia");
      this.requestApi({
        method: "POST",
        data: {
          cabecera_id: item.CABECERA_ID,
          awb: item.AWB,
        },
      })
        .then((res) => {
          //console.log(res.data);

          this.prealertaAwb = item.AWB;
          this.prealertaCabId = item.CABECERA_ID;
          this.overlay = false;
          this.dialogPreAlerta = true;
          this.preAlertas = res.data.prealertas;
        })
        .catch(() => {
          this.overlay = false;
        })
        .then(() => {
          this.overlay = false;
          this.setLoadingTable(false);
        });
    },
    dialogoPrealertaCorreo(item) {
      //this.prealertaMensaje.MENSAJE = item.MENSAJE;
      this.prealertaMensaje.DESTINATARIOS = item.DESTINATARIO;
      this.prealertaMensaje.AWB = item.AWB;
      this.prealertaMensaje.CABECERA_ID = item.CABECERA_ID;
      this.prealertaMensaje.ID = item.ID;
      this.prealertaMensaje.SCLIENTE_ID = item.SCLIENTE_ID;
      this.prealertaMensaje.ASUNTO = item.AWB + " Shipment Information";
      this.overlay = true;
      this.overlayText = "generando prealerta ..";
      this.setUrl("api/actualizarMensajePrealerta");
      this.requestApi({
        method: "POST",
        data: {
          id: item.ID,
        },
      })
        .then((res) => {
          this.overlay = false;
          //this.alertNotification({ param: { html: res.data.msg } });
          this.dialogPrealertaCorreo = true;
          this.prealertaMensaje.MENSAJE = res.data.alerta.MENSAJE;
          this.prealertaMensaje.DESTINATARIOS = res.data.alerta.DESTINATARIO;
        })
        .catch(() => {
          this.overlay = false;
        })
        .then(() => {
          this.overlay = false;
          this.setLoadingTable(false);
        });
    },
    enviarPrealerta() {
      this.overlay = true;
      this.overlayText = "enviando..";
      this.setUrl("api/enviarPrealerta");
      this.requestApi({
        method: "POST",
        data: {
          id: this.prealertaMensaje.ID,
          guia_id: this.prealertaMensaje.CABECERA_ID,
          awb: this.prealertaMensaje.AWB,
          destinatarios: this.prealertaMensaje.DESTINATARIOS,
          mensaje: this.prealertaMensaje.MENSAJE,
          remitente: this.prealertaMensaje.REMITENTE,
          scliente: this.prealertaMensaje.SCLIENTE_ID,
          asunto: this.prealertaMensaje.ASUNTO,
        },
      })
        .then((res) => {
          this.overlay = false;
          this.alertNotification({ param: { html: res.data.msg } });
          this.dialogMensajeCorreo = false;

          this.datosMensaje = {
            CABECERA_ID: "",
            AWB: "",
            DESTINATARIOS: "",
            MENSAJE: "",
            TIPO_MENSAJE: "",
            FWB: "",
            FHL: "",
            AEROLINEA_ID: "",
          };

          this.iniciarPreAlerta({
            CABECERA_ID: this.prealertaCabId,
            AWB: this.prealertaAwb,
          });
          this.dialogPrealertaCorreo = false;

          //this.cargarLista();
        })
        .catch(() => {
          this.overlay = false;
        })
        .then(() => {
          this.overlay = false;
          this.setLoadingTable(false);
        });
    },

    agendarPrealerta() {
      if (!this.$refs.formAgendarPre.validate()) {
        return false;
      }
      if (this.hawbSelected.length == 0) {
        alert("Seleccione al menos un cliente");
        return false;
      }
      this.overlay = true;
      //this.overlayText = "enviando..";
      this.setUrl("api/agendarPrealerta");

      this.requestApi({
        method: "POST",
        data: {
          prealertas: this.hawbSelected,
          fechahora: this.fechahora,
          asunto: this.asuntoPre,
        },
      })
        .then((res) => {
          //console.log(res.data);
          this.overlay = false;
          this.alertNotification({ param: { html: res.data.msg } });
          this.iniciarPreAlerta({
            CABECERA_ID: this.prealertaCabId,
            AWB: this.prealertaAwb,
          });
        })
        .catch(() => {
          this.overlay = false;
        })
        .then(() => {
          this.overlay = false;
          this.setLoadingTable(false);
        });
    },

    cargarLogPrealerta(item) {
      this.overlay = true;
      this.overlayText = "cargando..";
      this.setUrl("api/datosPrealertaLog");

      this.requestApi({
        method: "POST",
        data: {
          id: item.ID,
        },
      })
        .then((res) => {
          console.log(res.data.log);
          this.overlay = false;
          //this.alertNotification({ param: { html: res.data.msg } });
          this.prealertaLog = res.data.log;
          this.dialogPrealertaLog = true;
          this.logSel = "";
        })
        .catch(() => {
          this.overlay = false;
        })
        .then(() => {
          this.overlay = false;
          this.setLoadingTable(false);
        });
    },
    cancelarAgendaPrealerta() {
      // if (!this.$refs.formAgendarPre.validate()) {
      //   return false;
      // }
      for (let i = 0; i < this.hawbSelected.length; i++) {
        if (this.hawbSelected[i].ESTADO == "ENVIADO") {
          alert("Solo se pueden cancelar los prealertas pendientes de envío");
          return;
        }
      }
      if (this.hawbSelected.length == 0) {
        alert("Seleccione al menos un cliente");
        return false;
      }
      this.overlay = true;
      //this.overlayText = "enviando..";
      this.setUrl("api/cancelarAgendaPrealerta");

      this.requestApi({
        method: "POST",
        data: {
          prealertas: this.hawbSelected,
        },
      })
        .then((res) => {
          //console.log(res.data);
          this.overlay = false;
          this.alertNotification({ param: { html: res.data.msg } });
          this.iniciarPreAlerta({
            CABECERA_ID: this.prealertaCabId,
            AWB: this.prealertaAwb,
          });
        })
        .catch(() => {
          this.overlay = false;
        })
        .then(() => {
          this.overlay = false;
          this.setLoadingTable(false);
        });
    },
    refrescarPrealerta() {
      this.iniciarPreAlerta({
        CABECERA_ID: this.prealertaCabId,
        AWB: this.prealertaAwb,
      });
    },
  },
  mounted() {
    document.title = "Sistema de carga";
    this.setTitleToolbar("GUIAS AEREAS");
    const dataTableElement = this.$refs.dtGuias.$el;

    this.cargarLista();
  },
  watch: {
    options: {
      handler() {
        //this.cargarLista()
        //this.$emit('cargarLista')
      },
      deep: true,
    },
  },
};
</script>
